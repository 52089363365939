import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { MIN_TABLET_MQ } from '../utils/presets'

const Nav = styled.nav({
  padding: `24px`,
  [MIN_TABLET_MQ]: {
    padding: `48px`,
    position: `fixed`,
    zIndex: 9999,
    top: 0,
    right: 0,
    left: 0,
    display: `flex`,
    justifyContent: `space-between`,
  }
})

const Navbar = () => {
  return (
    <Nav
      role="navigation"
      aria-label="main-navigation"
    >
      <h1><Link to="/">Oddd studio</Link></h1>
      <Link to="/about">about</Link>
    </Nav>
  )
}

export default Navbar
