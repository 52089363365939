import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { MIN_TABLET_MQ, MIN_LARGE_DISPLAY_MQ } from '../utils/presets'

const FooterWrapper = styled.div({
  padding: `24px`,
  [MIN_TABLET_MQ]: {
    zIndex: 9999,
    padding: `48px`,
    position: `fixed`,
    bottom: 0,
    right: 0,
    left: 0,
  },
  [MIN_LARGE_DISPLAY_MQ]: {
    display: `flex`,
    justifyContent: `space-between`,
  }
})

const EmailAddress = styled.div({
  marginTop: `auto`
})

const PhoneNumbers = styled.div({
  'a': {
    display: `block`
  },
  [MIN_LARGE_DISPLAY_MQ]: {
    textAlign: `right`,
  }
})

const Footer = () => (
  <FooterWrapper>
    <EmailAddress>
      <a href="mailto:odddstudio@gmail.com">odddstudio@gmail.com</a>
    </EmailAddress>
    <PhoneNumbers>
      <a href="tel:+30693271123">+30 693 2371123</a>
      <a href="tel:+447706172771">+44 7706 172771</a>
    </PhoneNumbers>
  </FooterWrapper>
)

export default Footer
