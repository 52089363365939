import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { css, Global } from '@emotion/core'
import styled from '@emotion/styled'
import { withPrefix } from 'gatsby'
import Transition from '../components/Transition'
import useSiteMetadata from '../components/SiteMetadata'
// import Wrapper from '../components/Wrapper'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import { MIN_TABLET_MQ, MIN_LARGER_DISPLAY_MQ } from '../utils/presets'

const UnderConstruction = styled.small({
  position: `fixed`,
  top: 0,
  left: `calc(100% - 96px)`,
  textAlign: `center`,
  transform: `rotate(90deg)`,
  transformOrigin: `left`,
  zIndex: 9999,
  width: `100vh`,
  pointerEvents: `none`,
  display: `none`,
  [MIN_LARGER_DISPLAY_MQ]: {
    display: `block`
  }
})

function Layout({children, location, data }) {
  const { title, description } = useSiteMetadata()
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content="/img/og-image.jpg" />
      </Helmet>
      <Global
        styles={css`
          html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:'';content:none}table{border-collapse:collapse;border-spacing:0}
          html {
            scroll-behavior: smooth;
          }
          body {
            font-family: "poppinsregular", "Helvetica", Arial, sans-serif;
            font-size: 24px;
            line-height: 35px;
            letter-spacing: 2px;
          }
          a {
            color: #000;
            text-decoration: none;
          }
          video {
            outline: none;
          }
          h1,h2,h3,h4,h6 {
            font-size: 24px;
            line-height: 35px;
            letter-spacing: 2px;
          }
          p {
            font-size: 24px;
            line-height: 35px;
            letter-spacing: 0.6px;
          }
          small {
            font-size: 24px;
            line-height: 35px;
            letter-spacing: 0.6px;
          }
          @media only screen and (min-width:48em) {
            body, h1,h2,h3,h4,h6 {
              font-size: 40px;
              line-height: 48px;
              letter-spacing: 3px;
            }
            p {
              font-size: 30px;
              line-height: 42px;
              letter-spacing: 1px;
            }
          }
        `}
      />
      <Navbar />
      <Footer />
      <UnderConstruction>{`under construction`}</UnderConstruction>
      <Transition location={location}>{children}</Transition>
      {/*<Wrapper title={title} pathname={location.pathname} data={data}>*/}
      {/*</Wrapper>*/}
    </div>
  )
}

Layout.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
}

export default Layout
