// em = 16px
export const LARGER_DISPLAY_WIDTH = '100em' // 1600px
export const LARGE_DISPLAY_WIDTH = '74em' // 1240px
export const DEFAULT_WIDTH = '64em' // 1024px
export const TABLET_WIDTH = '48em' // 768px
export const MOBILE_WIDTH = '375px' // '30em' // 480px
// below 375 are smaller phones like the iphone5

export const LARGER_DISPLAY_MQ =
  `@media only screen and (max-width:${LARGER_DISPLAY_WIDTH})`
export const LARGE_DISPLAY_MQ =
  `@media only screen and (max-width:${LARGE_DISPLAY_WIDTH})`
export const DEFAULT_MQ = `@media only screen and (max-width:${DEFAULT_WIDTH})`
export const TABLET_MQ = `@media only screen and (max-width:${TABLET_WIDTH})`
export const MOBILE_MQ = `@media only screen and (max-width:${MOBILE_WIDTH})`

export const MIN_LARGER_DISPLAY_MQ = `@media (min-width:${LARGER_DISPLAY_WIDTH})`
export const MIN_LARGE_DISPLAY_MQ = `@media (min-width:${LARGE_DISPLAY_WIDTH})`
export const MIN_DEFAULT_MQ = `@media (min-width:${DEFAULT_WIDTH})`
export const MIN_TABLET_MQ = `@media (min-width:${TABLET_WIDTH})`
export const MIN_MOBILE_MQ = `@media (min-width:${MOBILE_WIDTH})`
